import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

const Contact = ({ data }) => {
  return (
    <Layout>
      <Helmet>
      <title>Contact</title>
    </Helmet>
      <section class="hero is-halfheight hero-banner" id="contact-hero">
            <div class="hero-body">
            </div>
      </section>
      <main>
          <h1>THE KEEP FOUNDATION, INC.</h1>
          <div class="columns has-text-centered">
            <div class="column"><p><strong>Email:</strong><br />info@horseswork.com</p></div>
            <div class="column"><p><strong>Call:</strong><br />859.259.0007</p></div>
            <div class="column"><p><strong>Write:</strong><br />P.O. Box 54972<br />Lexington, KY 40555</p></div>
          </div>
          <h1>Board of Directors</h1>
          <div class="columns">
              <div class="column"><strong>Katie LaMonica</strong> - Chairman<br />Godolphin</div>
              <div class="column"><strong>Shannon Cobb</strong> - Treasurer<br />The Red Mile</div>
              <div class="column"><strong>Price Bell</strong><br />Mill Ridge Farm</div>
          </div>
          <div class="columns">
              <div class="column"><strong>Gay Bredin</strong><br />Mt. Brilliant Farm</div>
              <div class="column"><strong>Case Clay</strong><br />Three Chimney’s Farm</div>
              <div class="column"><strong>Debra Hamelback</strong><br />Kentucky Veterinary Medical Association</div>
          </div> 
          <div class="columns">
              <div class="column"><strong>Chris Hoskins</strong><br />Jackson Kelly, PLLC</div>
              <div class="column"><strong>Elisabeth Jensen</strong><br />KEEP Alliance</div>
              <div class="column"><strong>Fred Sarver</strong><br />Cornerstone Farm</div>
          </div>
          <div class="columns">
              <div class="column"><strong>Reese Koffler-Stanfield</strong><br />Maplecrest Farm</div>
              <div class="column"><strong>James MacLeod, VMD, Ph.D.</strong><br />University of Kentucky</div>
              <div class="column"><strong>Kevin O’Keeffe</strong><br />Kevin O’Keeffe Law Office</div>
          </div>
        </main>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`